import './../../../style/solutionsPopup/Test2.css';
import { useTranslation } from "react-i18next";

function Test2() {

    // internationalization
    const {t} = useTranslation();

    return (
        <div>

        </div>
    )}

export default Test2;