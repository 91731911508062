/* imports */
import './../style/offer.css';
import { useTranslation } from "react-i18next";
import React, {useEffect, useRef} from "react";

/* Sub-menu icons */
import implementation from './../img/offer/implementation.svg'
import brainstorming from './../img/offer/brainstorming.svg'
import growth from './../img/offer/growth.svg'
import liveChat from './../img/offer/live-chat.svg'

/* Gradient background */
import gradient from './../img/offer/gradient.webp'

function Offer() {

    // internationalization
    const {t} = useTranslation();

    // sub-menu animations
    const connectingLineColor = "#4b3723";
    const connectingLineThickness = 2;
    const line1Ref = useRef(document.getElementById("line1"));
    const line2Ref = useRef(document.getElementById("line2"));
    const line3Ref = useRef(document.getElementById("line3"));

    const getOffset = (el) => {
        const rect = el.getBoundingClientRect();
        return {
            left: rect.left + window.scrollX,
            top: rect.top + window.scrollY,
            width: rect.width || el.offsetWidth,
            height: rect.height || el.offsetHeight
        };
    }

    const connect = (div) => {

        // get lines divs
        const line1 = line1Ref.current;
        const line2 = line2Ref.current;
        const line3 = line3Ref.current;

        // calculate offset
        const off1 = getOffset(document.getElementById("topTile"));
        const off2 = getOffset(document.getElementById("connectingLinesSpace"));
        const off3 = getOffset(div);

        // first point
        const x1 = off1.left;
        const y1 = off1.top + 1;

        // second point
        const x2 = off1.left;
        const y2 = off2.top + off2.height / 2;

        // third point
        const x3 = off3.left + off3.width / 2;
        const y3 = off2.top + off2.height / 2;

        // forth point
        const x4 = off3.left + off3.width / 2;
        const y4 = off3.top + off3.height;

        // calculate length of each line
        const length1 = Math.sqrt(((x2 - x1) * (x2 - x1)) + ((y2 - y1) * (y2 - y1)));
        const length2 = Math.sqrt(((x3 - x2) * (x3 - x2)) + ((y3 - y2) * (y3 - y2)));
        const length3 = Math.sqrt(((x3 - x4) * (x3 - x4)) + ((y3 - y4) * (y3 - y4)));

        // calculate corrected coordinates
        const cx1 = ((x1 + x2) / 2) - (length1 /2) + (connectingLineThickness / 2);
        const cy1 = ((y1 + y2) / 2) - (connectingLineThickness / 2);

        const cx2 = ((x2 + x3) / 2) - (length2 /2) + (connectingLineThickness / 2);
        const cy2 = ((y2 + y3) / 2) - (connectingLineThickness / 2);

        const cx3 = ((x3 + x4) / 2) - (length3 /2) + (connectingLineThickness / 2);
        const cy3 = ((y3 + y4) / 2) - (connectingLineThickness / 2);

        // calculate rotation
        const angle1 = Math.atan2((y1 - y2), (x1 - x2)) * (180 / Math.PI);
        const angle2 = Math.atan2((y2 - y3), (x2 - x3)) * (180 / Math.PI);
        const angle3 = Math.atan2((y3 - y4), (x3 - x4)) * (180 / Math.PI);

        // set style for line 1
        line1.style.padding = "0px";
        line1.style.margin = "0px";
        line1.style.height = connectingLineThickness + "px";
        line1.style.backgroundColor = connectingLineColor;
        line1.style.lineHeight = "0px";
        line1.style.position = "absolute";
        line1.style.left = cx1 + "px";
        line1.style.top = cy1 + "px";
        line1.style.width = length1 + "px";
        line1.style.transform = "rotate(" + angle1 + "deg)";
        line1.style.opacity = "1";

        // set style for line 2
        line2.style.padding = "0px";
        line2.style.margin = "0px";
        line2.style.height = connectingLineThickness + "px";
        line2.style.backgroundColor = connectingLineColor;
        line2.style.lineHeight = "0px";
        line2.style.position = "absolute";
        line2.style.left = cx2 + "px";
        line2.style.top = cy2 + "px";
        line2.style.width = length2 + "px";
        line2.style.transform = "rotate(" + angle2 + "deg)";
        line2.style.opacity = "1";

        // set style for line 3
        line3.style.padding = "0px";
        line3.style.margin = "0px";
        line3.style.height = connectingLineThickness + "px";
        line3.style.backgroundColor = connectingLineColor;
        line3.style.lineHeight = "0px";
        line3.style.position = "absolute";
        line3.style.left = cx3 + "px";
        line3.style.top = cy3 + "px";
        line3.style.width = length3 + "px";
        line3.style.transform = "rotate(" + angle3 + "deg)";
        line3.style.opacity = "1";
    }

    const disconnect = () => {
        const line1 = line1Ref.current;
        const line2 = line2Ref.current;
        const line3 = line3Ref.current;

        line1.style.opacity = "0";
        line2.style.opacity = "0";
        line3.style.opacity = "0";
    }

    // sub-menu scrolling effect
    const implementations = useRef(null)
    const businessAndConsulting = useRef(null)
    const financeAndAccounting = useRef(null)
    const itSupport = useRef(null)

    const scrollToImplementations = () => {
        implementations.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });
    }

    const scrollToBusinessAndConsulting = () => {
        businessAndConsulting.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });
    }

    const scrollToFinanceAndAccounting = () => {
        financeAndAccounting.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });
    }

    const scrollToItSupport = () => {
        itSupport.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });
    }

    // scroll-driven/intersection animations
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('offer-show');
            }
            else {
                entry.target.classList.remove('offer-show');
            }
        })
    });

    const hiddenElements = document.querySelectorAll('.offer-hidden');
    hiddenElements.forEach((element) => observer.observe(element));

    // scroll to top
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (
        <div className="offer">

            {/* Gradient */}
            <div className="offer-gradient-background">
                <img src={gradient} alt="gradient" />
            </div>

            {/* Background images */}
            <div className="offer-background"/>

            <div className="offer-page-container">

                {/* navigation background */}
                <div className="offer-navigation-background"/>

                {/* page title */}
                <div className="offer-page-title-section">
                    <div className="offer-page-title-company-name">
                        Humane Business Progress
                    </div>
                    <div className="offer-page-title">
                        {t("offer.page-title")}
                    </div>
                </div>

                {/* Sub-menu */}
                <div className="offer-submenu-section">

                    <div className="offer-submenu-item">
                        <div id="implementationsContainer" className="offer-submenu-item-inner-container"
                            onMouseEnter={() => connect(document.getElementById("implementationsContainer"))}
                            onMouseLeave={() => disconnect()}
                            onClick={scrollToImplementations}
                        >
                            <div className="offer-submenu-item-icon-container">
                                <img className="offer-submenu-item-icon" src={implementation} alt=""/>
                            </div>
                            <div className="offer-submenu-item-text">
                                {t("offer.sub-menu.implementations.title")}
                            </div>
                        </div>
                    </div>

                    <div className="offer-submenu-item">
                        <div id="businessAndConsultingContainer" className="offer-submenu-item-inner-container"
                            onMouseEnter={() => connect(document.getElementById("businessAndConsultingContainer"))}
                            onMouseLeave={() => disconnect()}
                            onClick={scrollToBusinessAndConsulting}
                        >
                            <div className="offer-submenu-item-icon-container">
                                <img className="offer-submenu-item-icon" src={brainstorming} alt=""/>
                            </div>
                            <div className="offer-submenu-item-text">
                                {t("offer.sub-menu.business-consulting.title")}
                            </div>
                        </div>
                    </div>

                    <div className="offer-submenu-item">
                        <div id="financeAndAccountingContainer" className="offer-submenu-item-inner-container"
                            onMouseEnter={() => connect(document.getElementById("financeAndAccountingContainer"))}
                            onMouseLeave={() => disconnect()}
                            onClick={scrollToFinanceAndAccounting}
                        >
                            <div className="offer-submenu-item-icon-container">
                                <img className="offer-submenu-item-icon" src={growth} alt=""/>
                            </div>
                            <div className="offer-submenu-item-text">
                                {t("offer.sub-menu.finance-and-accounting.title")}
                            </div>
                        </div>
                    </div>

                    <div className="offer-submenu-item">
                        <div id="itSupportContainer" className="offer-submenu-item-inner-container"
                            onMouseEnter={() => connect(document.getElementById("itSupportContainer"))}
                            onMouseLeave={() => disconnect()}
                            onClick={scrollToItSupport}
                        >
                            <div className="offer-submenu-item-icon-container">
                                <img className="offer-submenu-item-icon" src={liveChat} alt=""/>
                            </div>
                            <div className="offer-submenu-item-text">
                                {t("offer.sub-menu.it-support.title")}
                            </div>
                        </div>
                    </div>

                </div>


                {/* connecting lines */}
                <div id="connectingLinesSpace" className="offer-connecting-lines-container">
                    <div id="line1" ref={line1Ref}/>
                    <div id="line2" ref={line2Ref}/>
                    <div id="line3" ref={line3Ref}/>
                </div>


                {/* tiles section */}
                <div className="offer-tiles-section">

                    <div className="offer-tile-container" ref={implementations}>
                        <div className="offer-tile-inner-container">
                            <div id="topTile" className="offer-left-tile-title offer-hidden">
                                {t("offer.sub-menu.implementations.title")}
                            </div>

                            <div className="offer-left-tile-icon-container">
                                <div className="offer-tile-icon">
                                    <img src={implementation} alt=""/>
                                </div>
                            </div>

                            <div className="offer-left-tile-text-container offer-hidden">
                                <div className="offer-tile-text">
                                    {t("offer.sub-menu.implementations.text-line-1")}
                                </div>

                                <div className="offer-tile-text">
                                    {t("offer.sub-menu.implementations.text-line-2")}
                                </div>

                                <div className="offer-tile-text">
                                    {t("offer.sub-menu.implementations.text-line-3")}
                                </div>

                                <div className="offer-tile-text">
                                    {t("offer.sub-menu.implementations.text-line-4")}
                                </div>

                                <div className="offer-tile-text">
                                    {t("offer.sub-menu.implementations.text-line-5")}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="offer-tile-container" ref={businessAndConsulting}>
                        <div className="offer-tile-inner-container">
                            <div className="offer-right-tile-title offer-hidden">
                                {t("offer.sub-menu.business-consulting.title")}
                            </div>

                            <div className="offer-right-tile-icon-container">
                                <div className="offer-tile-icon">
                                    <img src={brainstorming} alt=""/>
                                </div>
                            </div>

                            <div className="offer-right-tile-text-container offer-hidden">
                                <div className="offer-tile-text">
                                    {t("offer.sub-menu.business-consulting.text-line-1")}
                                </div>

                                <div className="offer-tile-text">
                                    {t("offer.sub-menu.business-consulting.text-line-2")}
                                </div>

                                <div className="offer-tile-text">
                                    {t("offer.sub-menu.business-consulting.text-line-3")}
                                </div>

                                <div className="offer-tile-text">
                                    {t("offer.sub-menu.business-consulting.text-line-4")}
                                </div>

                                <div className="offer-tile-text">
                                    {t("offer.sub-menu.business-consulting.text-line-5")}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="offer-tile-container" ref={financeAndAccounting}>
                        <div className="offer-tile-inner-container">
                            <div className="offer-left-tile-title offer-hidden">
                                {t("offer.sub-menu.finance-and-accounting.title")}
                            </div>

                            <div className="offer-left-tile-icon-container">
                                <div className="offer-tile-icon">
                                    <img src={growth} alt=""/>
                                </div>
                            </div>

                            <div className="offer-left-tile-text-container offer-hidden">
                                <div className="offer-tile-text">
                                    {t("offer.sub-menu.finance-and-accounting.text-line-1")}
                                </div>

                                <div className="offer-tile-text">
                                    {t("offer.sub-menu.finance-and-accounting.text-line-2")}
                                </div>

                                <div className="offer-tile-text">
                                    {t("offer.sub-menu.finance-and-accounting.text-line-3")}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="offer-tile-container" ref={itSupport}>
                        <div className="offer-tile-inner-container">
                            <div id="offer-last-tile-title">
                                <div className="offer-right-tile-title offer-hidden">
                                    {t("offer.sub-menu.it-support.title")}
                                </div>
                            </div>

                            <div className="offer-right-tile-icon-container">
                                <div className="offer-tile-icon">
                                    <img src={liveChat} alt=""/>
                                </div>
                            </div>

                            <div id="offer-last-tile-text-container" className="offer-right-tile-text-container offer-hidden">
                                <div className="offer-tile-text">
                                    {t("offer.sub-menu.it-support.text-line-1")}
                                </div>

                                <div className="offer-tile-text">
                                    {t("offer.sub-menu.it-support.text-line-2")}
                                </div>

                                <div className="offer-tile-text">
                                    {t("offer.sub-menu.it-support.text-line-3")}
                                </div>

                                <div className="offer-tile-text">
                                    {t("offer.sub-menu.it-support.text-line-4")}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            {/* Page divider cuz of black line bug */}
            <div className="offer-divider"/>

        </div>
    )
}

export default Offer
