import './../../../style/solutionsPopup/Test1.css';
import { useTranslation } from "react-i18next";

function Test1() {

    // internationalization
    const {t} = useTranslation();

    return (
        <div>
asdasdasasd
        </div>
    )}

export default Test1;